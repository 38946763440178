// <!-- 검색 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 검색 페이지 보이기/숨기기  ]
function searchJS(){
    const Hash = window.location.hash;
    const Search = document.querySelector(".search");
    if(Hash.includes('#search')){
        Search.style="display:block";
    } else {
        Search.style="display:none";
    }
}
if(UserID){
    window.addEventListener('hashchange', searchJS);
    window.addEventListener('load', searchJS);
    document.getElementById('searchBackIcon').addEventListener('click', function () {
        window.history.back();
    });
}



//// 검색 카테고리 버튼 클릭 시 
function searchCategoryBtn() {
    const Hash = window.location.hash;
    if(!Hash.includes('#search')) return;
    const VideoBtnText = document.querySelector(".search-videoBtn span");
    const CreatorBtnText = document.querySelector(".search-creatorBtn span");
    const VideoBtnBottom = document.querySelector(".search-videoBtnBottom");
    const CreatorBtnBottom = document.querySelector(".search-creatorBtnBottom");
    const SearchVideoBlockBg = document.querySelector(".sVvideoBlock-bg");
    const CreatorBlockBg = document.querySelector(".creatorBlock-bg");
    
    SearchVideoBlockBg.style="display:none";
    CreatorBlockBg.style="display:none";

    CreatorBtnText.style="color:rgb(114, 114, 114);";
    VideoBtnText.style="color:black;";
    VideoBtnBottom.style ="background-color:black;";
    CreatorBtnBottom.style ="background-color:white;";
    SearchVideoBlockBg.style="display:block";
    CreatorBlockBg.style="display:none";

    VideoBtnText.addEventListener('click', function() {
      CreatorBtnText.style="color:rgb(114, 114, 114);";
      VideoBtnText.style="color:black;";
      VideoBtnBottom.style ="background-color:black;";
      CreatorBtnBottom.style ="background-color:white;";
      SearchVideoBlockBg.style="display:block";
      CreatorBlockBg.style="display:none";
    });
    CreatorBtnText.addEventListener('click', function() {
      CreatorBtnText.style="color:black;";
      VideoBtnText.style="color:rgb(114, 114, 114);";
      VideoBtnBottom.style ="background-color:white;";
      CreatorBtnBottom.style ="background-color:black;";
      SearchVideoBlockBg.style="display:none";
      CreatorBlockBg.style="display:block";
    });
}
if (UserID) {
  window.addEventListener('hashchange', searchCategoryBtn);
  window.addEventListener('load', searchCategoryBtn);
}





//// [ 검색어 정보 가져오기 ]
function searchFetch() {
    const Hash = window.location.hash;
    const SearchVideoBlockBg = document.querySelector(".sVvideoBlock-bg");
    const SearchVideoBlock = document.querySelector(".sVvideoBlock");
    const CreatorBlockBg = document.querySelector(".creatorBlock-bg");
    if (Hash.includes('#search')) {
        SearchVideoBlockBg.style.display ="block";
        SearchVideoBlock.innerHTML = "";
        const SearchValue = Hash.split('/')[1];
        const decodedValue = decodeURIComponent(SearchValue); // URL 디코딩
        const Value = decodedValue.replace(/\s+/g, ''); // 띄어쓰기를 제거
        
        // 초기화
        SearchVideoBlock.innerHTML = "";
        CreatorBlockBg.innerHTML = "";

        fetch('/api/searchVideo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ searchTerm: Value })
        })
        .then(response => response.json())
        .then(data => {
            if(data.video){
                data.video.forEach(item => {
                    const Div = document.createElement('div');
                    Div.innerHTML = `
                        <a class="sV-block" href="#videodetail/sV/${item._id}" data-id="${item._id}">
                            ${item.thumbnailUrl === "thumbnail-None" ? `
                                <video class="video" controlsList="nodownload"></video>
                            ` : `
                                <img src="${item.thumbnailUrl}" class="img" alt="Img">
                                <video class="video" controlsList="nodownload" style="display:none"></video>
                            `}
                            <div class="sV-block-info hidden">
                                <div class="creatorAvatarUrl">${item.creator.avatarUrl ? item.creator.avatarUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" }</div>
                                <div class="creatorName">${item.creator.name}</div>
                                <div class="creatorId">${item.creator._id}</div>
                                <div class="title">${item.title}</div>
                                <div class="videoUrl">${item.videoUrl}</div>
                            </div>
                        </a>
                    `;
                    if(SearchVideoBlock){
                        SearchVideoBlock.appendChild(Div);

                        const AllSvBlock = document.querySelectorAll('.sV-block');
                        AllSvBlock.forEach((block,index) => {
                          const Video = block.querySelector('video');
                          if(Video){
                            const blockVideoUrl = block.querySelector('.videoUrl').textContent;
                            const VideoUrl = `https://video.ponigress.com/${blockVideoUrl}`;
                    
                            if (Hls.isSupported()) {
                              const hls = new Hls();
                              hls.loadSource(VideoUrl);
                              hls.attachMedia(Video);
                              hls.on(Hls.Events.MANIFEST_PARSED, function () {
                                hls.currentLevel = 1;
                              });
                              const handleCanPlay = () => {
                                Video.muted = true;
                                Video.loop = true;
                                Video.currentTime = 0.5;
                                Video.removeEventListener('canplaythrough', handleCanPlay);
                                setTimeout(() => {
                                  Video.play();
                                }, 1000);
                              };
                              Video.addEventListener('canplaythrough', handleCanPlay);
                            } else if (Video.canPlayType('application/vnd.apple.mpegurl')) {
                              Video.src = VideoUrl;
                            }
                          }
                        });
                    }
                });
            }

            if(data.creator){
                data.creator.forEach(item => {
                    const Div = document.createElement('div');
                    Div.innerHTML = `
                        <div class="creatorBlock">
                            <a href="/#creator/${item._id}">
                                <img src="${item.avatarUrl ? item.avatarUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"}" alt="">
                                <span>${item.name}</span>
                            </a>
                            <button>팔로잉</button>
                        </div>
                    `;
                    if(CreatorBlockBg){
                        CreatorBlockBg.appendChild(Div);
                    }
                });
            }
        })
    }else if(Hash.includes('#videodetail/sV')){
        SearchVideoBlockBg.style.display ="none";
        CreatorBlockBg.innerHTML = "";
    }else{
        SearchVideoBlockBg.style.display ="none";
        SearchVideoBlock.innerHTML = "";
        CreatorBlockBg.innerHTML = "";
    }
}
if (UserID) {
    window.addEventListener('load', searchFetch);
    window.addEventListener('hashchange', searchFetch);
}