// <!-- 프로필수정 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 프로필 편집 언어번역 ]
function editProfileTrans() {
  const EditNameTitle = document.querySelector(".edit_name-title");
  const EditUserAccountText = document.querySelector(".edit_userAccountText");
  const EditProfileSubmit = document.querySelector(".editProfile_submit");

  const EditProfileText = {
    en: { // 영어
      EditNameTitle: "User Name",
      EditUserAccountText: "Account Settings",
      EditProfileSubmit: "Confirm"
    },
    ko: { // 한국어
      EditNameTitle: "사용자 이름",
      EditUserAccountText: "계정설정",
      EditProfileSubmit: "확인"
    }
  };

  const Language = navigator.language.split("-")[0];
  const Text = EditProfileText[Language] || EditProfileText.en;

  if (EditNameTitle) EditNameTitle.textContent = Text.EditNameTitle;
  if (EditUserAccountText) EditUserAccountText.textContent = Text.EditUserAccountText;
  if (EditProfileSubmit) EditProfileSubmit.textContent = Text.EditProfileSubmit;
}
if (UserID) {
  editProfileTrans();
}




//// [ 프로필수정 이름체크 알림창 - 언어번역 ]
function editProfileNameCheck(){
  const message = {
    en: "Please enter your user name", // 영어
    ko: "사용자 이름을 입력하여주세요", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}








//// [ 프로필수정 해쉬/로드 ]
function editProfileJS(){
  const EditProfile = document.querySelector(".EditProfile");
  const EditAvatarInput = document.querySelector('.editAvatarInput');
  const EditAvatarImg = document.querySelector('.editAvatarImg');
  const EditName = document.querySelector('.edit_name');
  const UserInfoAvatar = document.querySelector(".userInfo_avatar");
  const UserInfoName = document.querySelector(".userInfo_name");

  const Hash = window.location.hash;
  if(Hash.includes(`#edit-profile/${UserID.innerText}`)){
    EditProfile.style.display="flex";
    EditAvatarInput.value = ""; // 선택된 파일 초기화
    EditAvatarImg.src = UserInfoAvatar.src;
    EditName.value = UserInfoName.textContent;
  }else{
    EditProfile.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', editProfileJS);
  window.addEventListener('load', editProfileJS);
}






//// [ 프로필수정 - 아바타 추출 ]
function editAvatarChange(){
  const EditAvatarImg = document.querySelector('.editAvatarImg');
  const EditAvatarInput = document.querySelector('.editAvatarInput');
  EditAvatarImg.addEventListener('click', () => { EditAvatarInput.click()});
  EditAvatarInput.addEventListener('change', function(event) {
    const File = event.target.files[0];
    if (File) {
      const Reader = new FileReader();
      Reader.onload = function(e) {
        EditAvatarImg.src = e.target.result;
      };
      Reader.readAsDataURL(File);
    }
  });
}
if(UserID){
  editAvatarChange();
}







//// [ 프로필편집 제출클릭 ]
function editProfileSubmit() {
  const EditProfileSubmits = document.querySelector(".editProfile_submit");
  const EditName = document.querySelector(".edit_name");
  const EditAvatarInput = document.querySelector(".editAvatarInput");
  const EditAvatarImg = document.querySelector(".editAvatarImg");
  const UserInfoAvatar = document.querySelector(".userInfo_avatar");
  const UserInfoName = document.querySelector(".userInfo_name");
  const MobileBottomUserAvatar = document.querySelector(".mobileBottom-userAvatar");

  EditProfileSubmits.addEventListener("click", () => {

    // 사용자 경험(빠른)을 증가시키기 위함
    window.location.href = `https://www.ponigress.com/#users/${UserID.textContent}`;
    UserInfoAvatar.src = EditAvatarImg.src;
    UserInfoName.textContent = EditName.value;
    MobileBottomUserAvatar.src = EditAvatarImg.src;
    

    // 홈 비디오블럭 - 아바타, 이름 변경
    const Blocks = document.querySelectorAll('.block');
    Blocks.forEach(block => {
      const CreatorId = block.querySelector('.creatorId');
      const CreatorAvatarUrl = block.querySelector('.creatorAvatarUrl'); 
      const CreatorName = block.querySelector('.creatorName');
      const BottomCssCreatorAvatar = block.querySelector('.bottomCss-creator img');
      const BottomCssCreatorName = block.querySelector('.bottomCss-creator p');
      if(CreatorId.textContent === UserID.textContent){
        CreatorAvatarUrl.textContent = EditAvatarImg.src ;
        CreatorName.textContent = EditName.value ;
        BottomCssCreatorAvatar.src = EditAvatarImg.src ;
        BottomCssCreatorName.textContent = EditName.value ;
      }
    });
    
    // 유저 비디오블럭 - 아바타, 이름 변경
    const UvBlocks = document.querySelectorAll('.uV-block');
    UvBlocks.forEach(block => {
      const CreatorId = block.querySelector('.creatorId');
      const CreatorAvatarUrl = block.querySelector('.creatorAvatarUrl'); 
      const CreatorName = block.querySelector('.creatorName');
      if(CreatorId.textContent === UserID.textContent){
        CreatorAvatarUrl.textContent = EditAvatarImg.src ;
        CreatorName.textContent = EditName.value;
      }
    });


    // 유저 저장 비디오블럭 - 아바타, 이름 변경
    const UsBlocks = document.querySelectorAll('.uS-block');
    UsBlocks.forEach(block => {
      const CreatorId = block.querySelector('.creatorId');
      const CreatorAvatarUrl = block.querySelector('.creatorAvatarUrl');
      const CreatorName = block.querySelector('.creatorName');
      if(CreatorId.textContent === UserID.textContent){
        CreatorAvatarUrl.textContent = EditAvatarImg.src ;
        CreatorName.textContent = EditName.value;
      }
    });


    // fetch 업데이트
    const formData = new FormData();
    formData.append("name", EditName.value);
    formData.append("avatar", EditAvatarInput.files[0]);
    fetch(`/users/edit-profile`, { method: "POST", body: formData });
  });
}
if (UserID) {
  editProfileSubmit();
}