// <!-- 비디오 수정 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비디오 수정 언어번역 ]
function videoEditTrans() {
  const VideoEditHeadTitle = document.querySelector(".videoEdit_HeadTitle");
  const VideoEditTitle = document.querySelector(".videoEdit_title");
  const VideoEditSubmit = document.querySelector(".videoEdit_submit");
  const VideoDeleteTitle = document.querySelector(".videoDelete_title");
  const VideoDeleteSubmit = document.querySelector(".videoDelete_submit");

  const VideoEditText = {
    en: { // 영어
      VideoEditHeadTitle: "Edit Video",
      VideoEditTitle: "Title",
      VideoEditSubmit: "Submit",
      VideoDeleteTitle: "Do you want to delete this video?",
      VideoDeleteSubmit: "Delete"
    },
    ko: { // 한국어
      VideoEditHeadTitle: "비디오 수정",
      VideoEditTitle: "제목",
      VideoEditSubmit: "확인",
      VideoDeleteTitle: "게시물을 삭제 하시겠습니까?",
      VideoDeleteSubmit: "삭제"
    }
  };

  const Language = navigator.language.split("-")[0];
  const Text = VideoEditText[Language] || VideoEditText.en;

  if (VideoEditHeadTitle) VideoEditHeadTitle.textContent = Text.VideoEditHeadTitle;
  if (VideoEditTitle) VideoEditTitle.placeholder = Text.VideoEditTitle;
  if (VideoEditSubmit) VideoEditSubmit.textContent = Text.VideoEditSubmit;
  if (VideoDeleteTitle) VideoDeleteTitle.textContent = Text.VideoDeleteTitle;
  if (VideoDeleteSubmit) VideoDeleteSubmit.textContent = Text.VideoDeleteSubmit;
}
if (UserID) {
  videoEditTrans();
}




//// [ 비디오삭제 완료 알림창 - 언어번역 ]
function videoDeleteSucces() {
  const message = {
    en: "Video has been successfully deleted", // 영어
    ko: "비디오 삭제 완료 되었습니다", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}





//// [ 비디오 수정,삭제 ]
function videoEdit(){
  const Hash = window.location.hash;
  const VideoEdit = document.querySelector(".videoEdit");
  const VideoEditTitle = document.querySelector('.videoEdit_title');
  if(Hash.includes('#videoEdit')){
    VideoEdit.style.display="flex";
    const VideoId = Hash.replace('#videoEdit/', '');
    fetch(`/api/${VideoId}/videoEditData`, { method: "POST" })
    .then(response => response.json())
    .then(data => {
      VideoEditTitle.value= data.videos.title;
    })

    // 비디오 업데이트 버튼 클릭시
    const VideoEditForms = document.querySelector('.videoEditForm');
    const VideoEditSubmitBtn = document.querySelector(".videoEdit_submit");
    function videoEditSubmits(){
      VideoEditForms.action = `/api/${VideoId}/videoEdit`;
      VideoEditSubmitBtn.submit();
    }
    VideoEditSubmitBtn.addEventListener("click" , videoEditSubmits );


    // 비디오 삭제 버튼 클릭 시
    const VideoDeleteSubmitBtn = document.querySelector(".videoDelete_submit");
    function videoDeleteSubmits(){
      fetch(`/api/${VideoId}/videoDelete`, { method: "POST" })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            //
          } else {
            videoDeleteSucces();
            window.location.href = '/';
          }
        });
    }
    VideoDeleteSubmitBtn.addEventListener("click" , videoDeleteSubmits );
  }else{
    VideoEdit.style.display="none";
    VideoEditTitle.value = "";
  }
}
if(UserID){
  window.addEventListener('hashchange', videoEdit);
  window.addEventListener('load', videoEdit);
}