//  <!-- 모바일 뒤로가기 , 확인 아이콘 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 모바일 뒤로가기 ]
// 참고: #settlement/ 와 #settlementInfo/ 뒤에 / 를 붙였다.
function mobileBackJS() {
  const MobileBack = document.querySelector('.mobileBack');
  const PageTitle = document.querySelector('.pageTitle');
  const Confirm = document.getElementById('confirm');
  const Hash = window.location.hash;
  const validHashes = ['#edit-profile', '#change-password', '#withdrawal', '#videoupload', '#inquiryupload', '#videoEdit', '#userAccount'];

  const PageTitleText = {
    en: { // 영어
      editProfile: "Edit Profile",
      changePassword: "Change Password",
      withdrawal: "Delete Account",
      videoUpload: "Upload",
      inquiryUpload: "Contact Us",
      videoEdit: "Edit Video",
      userAccount: "Account Settings"
    },
    ko: { // 한국어
      editProfile: "프로필 편집",
      changePassword: "비밀번호 변경",
      withdrawal: "계정탈퇴",
      videoUpload: "업로드",
      inquiryUpload: "문의하기",
      videoEdit: "비디오 수정",
      userAccount: "계정설정"
    }
  };

  const Language = navigator.language.split("-")[0];
  const Text = PageTitleText[Language] || PageTitleText.en;

  if (window.innerWidth > 1000) {
    MobileBack.style = 'display:none';
  } else if (validHashes.some(validHash => Hash.includes(validHash))) {
    MobileBack.style = 'display:flex';
    if (Hash.includes('#edit-profile')) {
      PageTitle.textContent = Text.editProfile;
      Confirm.style.display = "grid";
    } else if (Hash.includes('#change-password')) {
      PageTitle.textContent = Text.changePassword;
      Confirm.style.display = "grid";
    } else if (Hash.includes('#withdrawal')) {
      PageTitle.textContent = Text.withdrawal;
      Confirm.style.display = "grid";
    } else if (Hash.includes('#videoupload')) {
      PageTitle.textContent = Text.videoUpload;
      Confirm.style.display = "grid";
    } else if (Hash.includes('#inquiryupload')) {
      PageTitle.textContent = Text.inquiryUpload;
      Confirm.style.display = "grid";
    } else if (Hash.includes('#videoEdit')) {
      PageTitle.textContent = Text.videoEdit;
      Confirm.style.display = "grid";
    } else if (Hash.includes('#userAccount')) {
      PageTitle.textContent = Text.userAccount;
      Confirm.style.display = "none";
    }
  } else {
    MobileBack.style = 'display:none';
  }
}
if (UserID) {
  window.addEventListener('hashchange', mobileBackJS);
  window.addEventListener('load', mobileBackJS);
  window.addEventListener('resize', mobileBackJS);
  document.getElementById('mobileBackIcon').addEventListener('click', function () {
      window.history.back();
  });
}



//// [ 모바일 확인 아이콘 클릭 시 ]
function confirmClick(){
  const Confirm = document.getElementById('confirm');
  Confirm.innerHTML = '<i class="bi bi-check-lg"></i>';
  const EditProfileSubmitBtn = document.querySelector('.editProfile_submit');
  const ChangePasswordSubmitBtn = document.querySelector('.changePassword_submit'); 
  const WithdrawalSubmitBtn = document.querySelector('.withdrawal_submit'); 
  const InquiryUploadSubmitBtn = document.querySelector('.inquiryUpload_submit');
  const VideoEditSubmit = document.querySelector('.videoEdit_submit'); 
  const VideoUploadSubmit = document.querySelector(".videoUpload_submit");
  Confirm.addEventListener('click', function() {
    const Hash = window.location.hash;
    if(Hash.includes('#edit-profile')){
      EditProfileSubmitBtn.click();
    }else if(Hash.includes('#change-password')){
      ChangePasswordSubmitBtn.click();
    }else if(Hash.includes('#withdrawal')){
      WithdrawalSubmitBtn.click();
    }else if(Hash.includes('#inquiryupload')){
      InquiryUploadSubmitBtn.click();
    }else if(Hash.includes('#videoEdit')){
      VideoEditSubmit.click();
    }else if(Hash.includes('#videoupload')){
      VideoUploadSubmit.click();
    }
  });
}
if(UserID){
  confirmClick();
}