// <!-- 비밀번호변경 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비밀번호 변경 언어번역 ]
function chanegPwTrans(){
  const NewPassword = document.querySelector(".newPassword");
  const NewPasswords = document.querySelector(".newPasswords");
  const ChangePasswordSubmit = document.querySelector(".changePassword_submit");
  const ChangePasswordTitle = document.querySelector(".changePassword_title");

  const ChanegPasswordText = {
    en: {  // 영어
      ChangePasswordTitle: "Change Password",
      NewPassword: "New Password",
      NewPasswords: "Confirm New Password",
      ChangePasswordSubmit: "Confirm"
    },
    ko: {  // 한국어
      ChangePasswordTitle: "비밀번호 변경",
      NewPassword: "새 비밀번호",
      NewPasswords: "새 비밀번호 확인",
      ChangePasswordSubmit: "확인"
    }
  }
  const Language = navigator.language.split("-")[0];
  const Text = ChanegPasswordText[Language] || ChanegPasswordText.en;

  if (ChangePasswordTitle) ChangePasswordTitle.textContent = Text.ChangePasswordTitle;
  if (NewPassword) NewPassword.placeholder = Text.NewPassword;
  if (NewPasswords) NewPasswords.placeholder = Text.NewPasswords;
  if (ChangePasswordSubmit) ChangePasswordSubmit.textContent = Text.ChangePasswordSubmit;
}
if(UserID){
  chanegPwTrans();
}



//// [ 비밀번호 모두 체크 알림창 - 언어번역 ]
function changePwAllCheck() {
  const message = {
    en: "Please enter all passwords", // 영어
    ko: "비밀번호를 모두 입력해주세요", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}




//// [ 비밀번호 서로 다른 입력 체크 알림창 - 언어번역 ]
function changePwDifferentCheck() {
  const message = {
    en: "The passwords in the two fields do not match", // 영어
    ko: "두 입력란의 비밀번호가 서로 맞지 않습니다", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}





//// [ 비밀번호변경 해쉬/로드 ]
function changePasswordJS(){
  const NewPassword = document.querySelector(".newPassword");
  const NewPasswords = document.querySelector(".newPasswords");
  const ChangePassword = document.querySelector(".changePassword");
  NewPassword.value = "";
  NewPasswords.value = "";
  if(window.location.hash === `#change-password/${UserID.innerText}`){
    ChangePassword.style.display="flex";
  } else {
    ChangePassword.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', changePasswordJS);
  window.addEventListener('load', changePasswordJS);
}






//// [ 비밀번호 제출 ]
function changePasswordSubmit(){
  const NewPassword = document.querySelector(".newPassword");
  const NewPasswords = document.querySelector(".newPasswords");
  const MeHeader = document.querySelector(".me_header");
  const ChangePasswordSubmit = document.querySelector(".changePassword_submit");
  function changePasswordFetch() {
    if ( NewPassword.value === "" || NewPasswords.value === "" ) {
      changePwAllCheck();
    } else if ( NewPassword.value !== NewPasswords.value ) {
      changePwDifferentCheck();
    } else {
      MeHeader.click();
      //alert("비밀번호 변경이 완료 되었습니다.");
      const NewPasswordData = NewPassword.value;
      fetch('/users/change-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newPassword: NewPasswordData })
      });
    }
  }
  ChangePasswordSubmit.addEventListener("click", changePasswordFetch);
}
if(UserID){
  changePasswordSubmit();
}