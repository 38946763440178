const UserID = document.querySelector(".userID");

//// [ 알림 모달창 ] notiMobileClose
const UploadNoti = document.querySelector('.upload_noti');
const MobileBottomNotiIcon = document.querySelector('.mobileBottom-noti i');
const NotiModalBg = document.querySelector('.notiModalBg');
const NotiModal = document.querySelector('.notiModal');
const NotiMobileClose = document.getElementById('notiMobileClose');
const NotiPcClose = document.getElementById('notiPcClose');

function openNoti(){
  NotiModalBg.style.display = "block";
}
function closeNoti(event) {
  if (!NotiModal.contains(event.target) || event.target === NotiMobileClose || event.target === NotiPcClose) {
    NotiModalBg.style.display = "none";
  }
}
function hashCloseNoti() {
  NotiModalBg.style.display = "none";
}
if (UserID) {
  MobileBottomNotiIcon.removeEventListener('click', openNoti);
  UploadNoti.removeEventListener('click', openNoti);
  MobileBottomNotiIcon.addEventListener('click', openNoti);
  UploadNoti.addEventListener('click', openNoti);
  NotiModalBg.removeEventListener('click', closeNoti);
  NotiModalBg.addEventListener('click', closeNoti);
  window.addEventListener('hashchange', hashCloseNoti);
}