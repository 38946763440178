// <!-- 비디오 업로드 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비디오 업로드 언어번역 ]
function videoUploadTrans(){
  const VideoUploadSubmit = document.querySelector(".videoUpload_submit");
  const VideoUploadTitle = document.querySelector(".videoUpload_title");
  
  const videoUploadText = {
    en: {  // 영어
      VideoUploadSubmit: "Upload",
      VideoUploadTitle:"text"
    },
    ko: {  // 한국어
      VideoUploadSubmit: "업로드",
      VideoUploadTitle:"문구"
    }
  }

  const Language = navigator.language.split("-")[0];
  const Text = videoUploadText[Language] || videoUploadText.en;

  if (VideoUploadSubmit) VideoUploadSubmit.textContent = Text.VideoUploadSubmit;
  if (VideoUploadTitle) VideoUploadTitle.placeholder = Text.VideoUploadTitle;
}
if(UserID){
  videoUploadTrans();
}



//// [ 비디오 업로드 보이기,숨기기 / 비디오업로드 값 초기화 ]
function videoUploadJS() {
  const VideoUpload = document.querySelector(".videoUpload");
  const PlusIcon = document.querySelector('.video_plusIcon');
  const ChoiceVideo = document.querySelector('.choice-video');
  const ChoiceThumbnail = document.querySelector('.choice-thumbnail');
  const ChoiceThumbnailText = document.querySelector('.choice-thumbnailText');
  const VideoUploadTitle = document.querySelector(".videoUpload_title");
  const VideoFile = document.querySelector('.videoFile');
  const ThumbnailFile = document.querySelector('.thumbnailFile');

  const Hash = window.location.hash;
  if(Hash.includes('#videoupload')){
    VideoUpload.style.display="flex";

    // 업로드 부분 초기화
    PlusIcon.style.display = "grid";
    ChoiceVideo.style.display = "none";
    ChoiceThumbnail.style.display = "none";
    ChoiceThumbnailText.style.display = "none";
    ChoiceVideo.src = "" ;
    ChoiceThumbnail.src = "" ;
    VideoFile.value = "";
    ThumbnailFile.value = "";
    VideoUploadTitle.value = "";
  } else {
    VideoUpload.style.display="none";
  }
}
if(UserID){
  window.addEventListener('hashchange', videoUploadJS);
  window.addEventListener('load', videoUploadJS);
}





//// [ 파일 체크 알림창 - 언어번역]
function fileCheck(){
  const message = {
    en: "Please select a video", // 영어
    ko: "파일을 선택하여 주세요", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}




//// [ 제목 체크 알림창 - 언어번역]
function titleValueCheck(){
  const message = {
    en: "Please enter a text", // 영어
    ko: "문구를 입력하여주세요", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}







//// [ 비디오 미리보기 ]
function videoPreview() {
  const PlusIcon = document.querySelector('.video_plusIcon');
  const VideoFile = document.querySelector('.videoFile');
  const ChoiceVideo = document.querySelector('.choice-video'); 
  const ChoiceThumbnailText = document.querySelector('.choice-thumbnailText');
  function fileChoice() {
    VideoFile.click();
  }

  const handleFileChange = (event) => {
    const File = event.target.files[0];
    if (File) {
      PlusIcon.style.display = "none";
      const reader = new FileReader();
      reader.onload = function(e) {
        ChoiceVideo.src = e.target.result;
        ChoiceVideo.style.display = "block";
        ChoiceThumbnailText.style.display = "block";
      };
      reader.readAsDataURL(File);
    } else {
      PlusIcon.style.display = "grid";
      ChoiceVideo.style.display = "none";
      ChoiceVideo.src = "" ;
      ChoiceThumbnailText.style.display = "none";
    }
  };

  // 기존 이벤트 제거
  PlusIcon.removeEventListener('click', fileChoice);
  ChoiceVideo.removeEventListener('click', fileChoice);
  VideoFile.removeEventListener('change', handleFileChange);
  
  // 이벤트 등록
  PlusIcon.addEventListener('click', fileChoice);
  ChoiceVideo.addEventListener('click', fileChoice);
  VideoFile.addEventListener('change', handleFileChange);
}
if(UserID){
  videoPreview();
}






//// [ 썸네일 미리보기 ]
function thumbnailPreview() {
  const ThumbnailFile = document.querySelector('.thumbnailFile');
  const ChoiceVideo = document.querySelector('.choice-video');
  const ChoiceThumbnail = document.querySelector('.choice-thumbnail');
  const ChoiceThumbnailText = document.querySelector('.choice-thumbnailText');
  function fileChoice() {
    ThumbnailFile.click();
  }

  const handleFileChange = (event) => {
    const File = event.target.files[0];
    if (File) {
      ChoiceVideo.style.display = "none";
      const reader = new FileReader();
      reader.onload = function(e) {
        ChoiceThumbnail.src = e.target.result;
        ChoiceThumbnail.style.display = "block";
      };
      reader.readAsDataURL(File);
    } else {
      ChoiceVideo.style.display = "block";
      ChoiceThumbnail.src = "" ;
    }
  };

  // 기존 이벤트 제거
  ChoiceThumbnailText.removeEventListener('click', fileChoice);
  ThumbnailFile.removeEventListener('change', handleFileChange);
  
  // 이벤트 등록
  ChoiceThumbnailText.addEventListener('click', fileChoice);
  ThumbnailFile.addEventListener('change', handleFileChange);
}
if(UserID){
  thumbnailPreview();
}







// S3에 video 업로드
async function videoUploadToS3(url, file) {
  const Response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file
  });

  if (!Response.ok) {
    throw new Error(`파일 업로드 실패: ${file.name}`);
  }
}



// S3에 thumbnail 업로드
async function thumbnailUploadToS3(url, file) {
  const Response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file
  });

  if (!Response.ok) {
    throw new Error(`파일 업로드 실패: ${file.name}`);
  }
}




// 썸네일 리사이징 함수 (넓이만 300으로 설정)
async function thumbnailResizeImg(imageFile, maxWidth) {
  const img = new Image();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  
  return new Promise((resolve, reject) => {
    img.onload = () => {
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height = Math.round((height * maxWidth) / width);
        width = maxWidth;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        resolve(blob);
      }, imageFile.type);
    };

    img.onerror = (error) => reject(error);
    img.src = URL.createObjectURL(imageFile);
  });
}




//// [ 비디오 업로드 제출 ]
async function videoUploadSubmit(){
  const VideoFile = document.querySelector(".videoFile").files[0];
  const ThumbnailFile = document.querySelector(".thumbnailFile").files[0];
  const VideoUploadTitle = document.querySelector(".videoUpload_title").value;
  const VideoUploadLanguage = navigator.language.split("-")[0];
  const VideoFileExtension = VideoFile ? VideoFile.type.split('/')[1] : null;
  const ThumbnailFileExtension = ThumbnailFile ? ThumbnailFile.type.split('/')[1] : null;

  try {
    const VideoPresignedRes = await fetch('/videos/videoPresigned', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        videoExtension: VideoFileExtension ,
        thumbnailExtension: ThumbnailFileExtension
      })
    });

    if (!VideoPresignedRes.ok) return;
    const { videoPreSignedUrl, thumbnailPreSignedUrl, videoURLS, thumbnailURLS } = await VideoPresignedRes.json();

    if(videoPreSignedUrl !== "video-None"){
      videoUploadToS3(videoPreSignedUrl, VideoFile);
    }

    if(thumbnailPreSignedUrl !== "thumbnail-None"){
      const ResizedThumbnailBlob = await thumbnailResizeImg(ThumbnailFile, 300);
      thumbnailUploadToS3(thumbnailPreSignedUrl, ResizedThumbnailBlob);
    }

    // S3 업로드가 완료된 후 '/api/videoUpload'로 데이터 전송
    const VideoUploadRes = await fetch('/videos/videoUpload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        videoUrl:videoURLS,
        thumbnailUrl:thumbnailURLS,
        title:VideoUploadTitle,
        videoLanguage:VideoUploadLanguage
      })
    });

    if (!VideoUploadRes.ok) {
      throw new Error('비디오 업로드 중 오류 발생');
    }

    const { videoId } = await VideoUploadRes.json();
    if (videoId) {
      window.location.href="https://www.ponigress.com/#home";
    }

  } catch (error) {
    console.error('업로드 중 오류 발생:', error);
  }
}



//// [ 비디오 업로드 벨류 체크 ]
function checkValue(){
  const File = document.querySelector(".videoFile").files[0];
  const VideoUploadTitle = document.querySelector(".videoUpload_title").value;
  if(!File){
    fileCheck();
  }else if(!VideoUploadTitle){
    titleValueCheck();
  }else{
    videoUploadSubmit();
  }
}
if(UserID){
  const VideoUploadSubmit = document.querySelector(".videoUpload_submit");
  VideoUploadSubmit.addEventListener('click', checkValue);
}