// <!-- 로그인 JAVASCRIPT -->
let isLoginBtnListenerAdded = false;
const Login = document.querySelector(".login");


//// [ 로그인 언어번역 ]
function loginTrans() {
  const LoginEmail = document.querySelector(".login_email"); // 이메일
  const LoginPassword = document.querySelector(".login_password"); // 패스워드
  const LoginBtn = document.querySelector(".login_btn"); // 로그인 (버튼 텍스트)
  const LoginForgotPassword = document.querySelector(".login_Forgot_Pw"); // 비밀번호를 잊으셨나요?
  const SignUpNow = document.querySelector(".signUpNow"); // 회원가입 바로가기
  const LoginText = {
    en: { // 영어
      LoginEmail: "Email",
      LoginPassword: "Password",
      LoginBtn: "Sign In",
      LoginForgotPassword: "Forgot Password?",
      SignUpNow: "Sign Up"
    },
    ko: { // 한국어
      LoginEmail: "이메일",
      LoginPassword: "비밀번호",
      LoginBtn: "로그인",
      LoginForgotPassword: "비밀번호를 잊으셨나요?",
      SignUpNow: "회원가입"
    }
  }
  const language = navigator.language.split("-")[0];
  const text = LoginText[language] || LoginText.en ;

  if (LoginEmail) LoginEmail.placeholder = text.LoginEmail;
  if (LoginPassword) LoginPassword.placeholder = text.LoginPassword;
  if (LoginBtn) LoginBtn.textContent = text.LoginBtn;
  if (LoginForgotPassword) LoginForgotPassword.textContent = text.LoginForgotPassword;
  if (SignUpNow) SignUpNow.innerHTML = text.SignUpNow + '<i class="bi bi-chat" style="margin-left:5px;"></i>';
}
if(Login){
  loginTrans();
}





//// [ 로그인 모두 체크 알림창 - 언어번역 ]
function loginAllCheck() {
  const message = {
    en: "Please enter both your email and password", // 영어
    ko: "이메일과 비밀번호를 모두 입력해주세요", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}




//// [ 이메일, 비번 확인 알림창 - 언어번역 ]
function invalidCredentials() {
  const message = {
    en: "Please check your email or password", // English
    ko: "이메일 또는 비밀번호을 확인 하여주시기 바랍니다", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}



//// [ 로그인 에러 발생 알림창 - 언어번역 ]
function errorOccurred() {
  const message = {
    en: "An error has occurred", // English
    ko: "에러가 발생하였습니다", // 한국어
  };
  const languages = navigator.language.split("-")[0];
  const Message = message[languages] || message.en;
  alert(Message);
}





//// [  페이지 보이기/숨기기 , 로그인 제출 ]
function loginSubmit() {
  const LoginEmail = document.querySelector(".login_email"); // 이메일
  const LoginPassword = document.querySelector(".login_password"); // 패스워드
  const LoginBtn = document.querySelector(".login_btn"); // 로그인 버튼
  if (window.location.hash === "#login") {
    Login.style.display = "flex"; // 로그인 페이지를 보여주자!

    //// [ 로그인 버튼로딩 및 제출 fetch ]
    if (LoginBtn !== null) {
      function LoginLoadings() {
        if (LoginEmail.value === "" || LoginPassword.value === "") {
          loginAllCheck();
        } else {
          LoginBtn.innerHTML = '<div class="loader"></div>'; // 로딩 표시 추가

          // 데이터를 서버에 보내기 위한 fetch 요청
          const loginData = {
            email: LoginEmail.value,
            password: LoginPassword.value
          };

          fetch('/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
          })
          .then(response => response.json())
          .then(data => {
            if (data.error) {
              // 에러 메시지 처리
              if(data.error === "invalidCredentials")invalidCredentials();
              if(data.error === "errorOccurred")errorOccurred();
              LoginBtn.innerHTML = '로그인';
            } else {
              window.location.href = '/';
            }
          });
        }
      }
      if (!isLoginBtnListenerAdded) {
        LoginBtn.addEventListener("click", LoginLoadings);
        isLoginBtnListenerAdded = true;
      }
    }
  } else {
    Login.style.display = "none"; // 로그인 페이지를 숨기자
    LoginEmail.value = "";
    LoginPassword.value = "";
  }
}
if (Login) {
  window.addEventListener('hashchange', loginSubmit);
  window.addEventListener('load', loginSubmit);
}