// <!-- 모바일 Bottom JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 모바일 bottom / 아이콘 색상변경 ]
function mobileBottomJS() {
  const MobileBottom = document.querySelector('.mobileBottom');
  const ValidHashes = ['#home', '#search', '#users', '#creator', '#mobilesearch', '#videodetail'];
  const Hash = window.location.hash;
  const MobileBottomHome = document.querySelector('.mobileBottom-home');
  const MobileBottomSearch = document.querySelector('.mobileBottom-search');
  const MobileBottomUpload = document.querySelector('.mobileBottom-upload');
  const MobileBottomNoti = document.querySelector('.mobileBottom-noti');
  const MobileBottomUserAvatar = document.querySelector('.mobileBottom-userAvatar');
  
  // PC 화면일 경우 모바일 하단 바 숨김
  if (window.innerWidth > 1000) {
    MobileBottom.style = 'display:none';
    return;
  }
  
  // style
  const Default = "color:rgb(141, 141, 141);";
  const Modified = "color:black;";
  const UserAvatarDefault  = "padding:0px; border:0px solid black;";
  const UserAvatarModified = "padding:1px; border:1px solid black;";

  // 해시가 유효한 경우
  const IsValidHash = ValidHashes.some(validHash => Hash.includes(validHash));
  if (!Hash || IsValidHash) {
    if(!Hash || Hash.includes('#home')){
      MobileBottomHome.style = Modified ;
      MobileBottomSearch.style = Default ;
      MobileBottomUpload.style = Default ;
      MobileBottomNoti.style = Default ;
      MobileBottomUserAvatar.style = UserAvatarDefault ;
      MobileBottom.style.backgroundColor = "white";
    } else if(Hash.includes('#mobilesearch')){
      MobileBottomHome.style = Default ;
      MobileBottomSearch.style = Modified ;
      MobileBottomUpload.style = Default ;
      MobileBottomNoti.style = Default ;
      MobileBottomUserAvatar.style = UserAvatarDefault ;
      MobileBottom.style.backgroundColor = "white";
    } else if(Hash.includes('#users')){
      MobileBottomHome.style = Default ;
      MobileBottomSearch.style = Default ;
      MobileBottomUpload.style = Default ;
      MobileBottomNoti.style = Default ;
      MobileBottomUserAvatar.style = UserAvatarModified ;
      MobileBottom.style.backgroundColor = "white";
    } else if(Hash.includes('#videodetail')){
      // 디테일 css 에는 따로 detail.js 에 지정
    } else { 
      MobileBottomHome.style = Default ;
      MobileBottomSearch.style = Default ;
      MobileBottomUpload.style = Default ;
      MobileBottomNoti.style = Default ;
      MobileBottomUserAvatar.style = UserAvatarDefault ;
      MobileBottom.style.backgroundColor = "white";
    }
    MobileBottom.style.display = 'flex';
  } else {
    MobileBottom.style.display = 'none';
  }
}
if (UserID) {
  window.addEventListener('hashchange', mobileBottomJS);
  window.addEventListener('load', mobileBottomJS);
  window.addEventListener('resize', mobileBottomJS);
}




//// [ 하단 바 로드,해시,리사이즈 색상변경 - 비디오 디테일 인 경우 ]
const BodyContent = document.querySelector('.bodyContent');
function backgroundScroll() {
  const Hash = window.location.hash;
  const MobileBottom = document.querySelector('.mobileBottom');
  const MobileBottomHome = document.querySelector('.mobileBottom-home');
  const MobileBottomSearch = document.querySelector('.mobileBottom-search');
  const MobileBottomUpload = document.querySelector('.mobileBottom-upload');
  const MobileBottomNoti = document.querySelector('.mobileBottom-noti');
  const StatusBarWhite = document.querySelector('.statusBarWhite'); // 상단
  const StatusBarBlack = document.querySelector('.statusBarBlack'); // 상단
  const NavigationBarWhite = document.querySelector('.navigationBarWhite'); // 하단
  const NavigationBarBlack = document.querySelector('.navigationBarBlack'); // 하단
  let width = window.innerWidth;
  
  // style
  const Default = "color:rgb(141, 141, 141);";
  const Modified = "color:white;";

  if(Hash.includes('#videodetail')){
    if(width <= 540){
      MobileBottomHome.style = Modified;
      MobileBottomSearch.style = Modified;
      MobileBottomUpload.style = Modified;
      MobileBottomNoti.style = Modified;
      MobileBottom.style.background = "black";
    } else {
      MobileBottomHome.style = Default;
      MobileBottomSearch.style = Default;
      MobileBottomUpload.style = Default;
      MobileBottomNoti.style = Default;
      MobileBottom.style.background = "white";
    }

    if (window.ReactNativeWebView) StatusBarBlack.click(); // 상단블랙
    if (window.ReactNativeWebView) NavigationBarBlack.click(); // 하단블랙
  } else if(Hash.includes('#webViewVideoUpload')){
    if (window.ReactNativeWebView) StatusBarBlack.click(); // 상단블랙
    if (window.ReactNativeWebView) NavigationBarBlack.click(); // 하단블랙
  } else{
    if (window.ReactNativeWebView) StatusBarWhite.click(); // 상단화이트
    if (window.ReactNativeWebView) NavigationBarWhite.click(); // 하단화이트
  }
}
if (UserID) {
  window.addEventListener('hashchange', backgroundScroll);
  window.addEventListener('load', backgroundScroll);
  window.addEventListener('resize', backgroundScroll);
}






//// [  하단 바 scroll 시 색상변경 - 비디오 디테일 인 경우 ]
function scroll(){
  const Hash = window.location.hash;
  const MobileBottom = document.querySelector('.mobileBottom');
  const MobileBottomHome = document.querySelector('.mobileBottom-home');
  const MobileBottomSearch = document.querySelector('.mobileBottom-search');
  const MobileBottomUpload = document.querySelector('.mobileBottom-upload');
  const MobileBottomNoti = document.querySelector('.mobileBottom-noti');
  const NavigationBarWhite = document.querySelector('.navigationBarWhite'); // 하단
  const NavigationBarBlack = document.querySelector('.navigationBarBlack'); // 하단
  let width = window.innerWidth;

  // style
  const Default = "color:rgb(141, 141, 141);";
  const Modified = "color:white;";
  
  if(Hash.includes('#videodetail') && width <= 540){
    if (BodyContent.scrollTop > 0) {
      MobileBottom.style.background = "white";
      MobileBottomHome.style = Default;
      MobileBottomSearch.style = Default;
      MobileBottomUpload.style = Default;
      MobileBottomNoti.style = Default;
      if (window.ReactNativeWebView) NavigationBarWhite.click(); // 상단화이트
    } else {
      MobileBottom.style.background = "black";
      MobileBottomHome.style = Modified;
      MobileBottomSearch.style = Modified;
      MobileBottomUpload.style = Modified;
      MobileBottomNoti.style = Modified;
      if (window.ReactNativeWebView) NavigationBarBlack.click(); // 하단블랙
    }
  }
}
if (UserID) {
  BodyContent.removeEventListener('scroll', scroll);
  BodyContent.addEventListener('scroll', scroll);
}






//// [ 웹뷰 체크 후 href 지정 ]
function uploadHrefChange(){
  const MobileBottomUpload = document.querySelector('.mobileBottom-upload');
  if (window.ReactNativeWebView) {
    MobileBottomUpload.href = `#webViewVideoUpload/${UserID.textContent}` ;
  } else {
    MobileBottomUpload.href = `#videoupload/${UserID.textContent}` ;
  }
}
if (UserID) {
  uploadHrefChange();
}