// <!-- 저장 JAVASCRIPT -->
const UserID = document.querySelector(".userID");



// 저장 버튼클릭 시 (여부 판단 후 저장 및 삭제)
const MainSaveBtn = document.querySelector(".main-saveBtn");
async function save(){
  const VideoId = document.querySelector('.main-videoId').textContent;
  const MainSaveValue = document.querySelector('.main-saveValue');
  if(MainSaveValue.textContent === "no"){
    MainSaveBtn.textContent = "저장됨" ;
    MainSaveBtn.style ="background-color:rgba(15, 98, 242, 0); color:white;";
    MainSaveValue.textContent = "yes";

    // 비디오 저장 및 정보 가져오기
    const VideoSaveHandle = await fetch(`/api/${VideoId}/save`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    }
    });
    if (!VideoSaveHandle.ok)return;
    const { item } = await VideoSaveHandle.json();


    // fetch 로 받아온걸 그대로 넣어주면 된다.
    const USvideoBlock = document.querySelector(".uSvideoBlock");
    const Div = document.createElement('div');
    Div.innerHTML = `
      <a class="uS-block" href="#videodetail/uS/${item._id}" data-id="${item._id}">
        ${item.thumbnailUrl === "thumbnail-None" ? `
          <video class="video" controlsList="nodownload"></video>
        ` : `
          <img src="${item.thumbnailUrl}" class="img" alt="Img">
          <video class="video" controlsList="nodownload" style="display:none"></video>
        `}
        <div class="uS-block-info hidden">
          <div class="creatorAvatarUrl">${item.creator.avatarUrl ? item.creator.avatarUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" }</div>
          <div class="creatorName">${item.creator.name}</div>
          <div class="creatorId">${item.creator._id}</div>
          <div class="title">${item.title}</div>
          <div class="videoUrl">${item.videoUrl}</div>
        </div>
      </a>
    `;
    USvideoBlock.prepend(Div);

    const Video = Div.querySelector('video');
    if(Video){
      const blockVideoUrl = Div.querySelector('.videoUrl').textContent;
      const VideoUrl = `https://video.ponigress.com/${blockVideoUrl}`;

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(VideoUrl);
        hls.attachMedia(Video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          hls.currentLevel = 1;
        });
        const handleCanPlay = () => {
          Video.muted = true;
          Video.loop = true;
          Video.currentTime = 0.5;
          Video.removeEventListener('canplaythrough', handleCanPlay);
          setTimeout(() => {
            Video.play();
          }, 1000);
        };
        Video.addEventListener('canplaythrough', handleCanPlay);
      } else if (Video.canPlayType('application/vnd.apple.mpegurl')) {
        Video.src = VideoUrl;
      }
    }
  } else { // 저장이 되어있는 경우
    MainSaveBtn.textContent = "저장" ;
    MainSaveBtn.style ="background-color:rgb(15, 98, 242); color:white;";
    MainSaveValue.textContent = "no";
    fetch(`/api/${VideoId}/save/cancle`, { method: "POST" });
    const UsBlock = document.querySelector(`.uS-block[data-id="${VideoId}"]`);
    UsBlock.remove();
  }
}
if (UserID) {
  MainSaveBtn.addEventListener("click", save);
}





//// [ 버튼 버블 효과 ]
function pop(e) {
  const MainSaveValue = document.querySelector('.main-saveValue');
  // 이미 위에 코드에서 no 에서 yes 로 변경되었기 때문에 yes 로 해주어야 한다. 
  if(MainSaveValue.textContent === "yes"){
    const maxParticles = 80; // 버블 수
    for (let i = 0; i < maxParticles; i++) {
      createParticle(e.clientX, e.clientY, e.target.dataset.type);
    }
  }
}
function createParticle(x, y, type) {
  const particle = document.createElement("particle");
  document.body.appendChild(particle);

  const size = Math.floor(Math.random() * 40 + 5);

  particle.style.width = `${size}px`;
  particle.style.height = `${size}px`;

  const destinationX = x + (Math.random() - 0.5) * 2 * 350; 
  const destinationY = y + (Math.random() - 0.5) * 2 * 450;

  particle.style.background = `linear-gradient( to right,rgb(165, 212, 254),rgb(25, 160, 232))`;
  particle.style.borderRadius = "50%";

  const animation = particle.animate(
    [
      {
        transform: `translate(${x - size / 2}px, ${y - size / 2}px)`,
        opacity: 1,
      },
      {
        transform: `translate(${destinationX}px, ${destinationY}px)`,
        opacity: 0,
      },
    ],
    {
      duration: 10 + Math.random() * 4000, // 속도 빠르기 
      easing: "cubic-bezier(0, .9, .57, 1)",
      delay: Math.random() * 50,
    }
  );
  animation.onfinish = () => {
    particle.remove();
  };
}
if (UserID) {
  MainSaveBtn.addEventListener("click", pop);
}
































