// <!-- 크리에이터 디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 크리에이터 디테일 보이기/숨기기 ]
function creatorDetailJS() {
  const Hash = window.location.hash;
  const CreatorDetailBg = document.querySelector(".creatorDetail-bg");
  if (Hash.includes('#creator')) {
    CreatorDetailBg.style ="width:100%; height:auto; visibility:visible; overflow:visible;";
  }else{
    CreatorDetailBg.style ="width:0; height:0; visibility:hidden; overflow:hidden; padding-top:0px;";
  }
}
if (UserID) {
  window.addEventListener("load", creatorDetailJS);
  window.addEventListener("hashchange", creatorDetailJS);
}




//// [ 크리에이터 정보 가져오기 ]
async function creatorInfo() {
  const Hash = window.location.hash;
  const CreatorInfoAvatar = document.querySelector(".creatorInfo_avatar");
  const CreatorInfoName = document.querySelector(".creatorInfo_name");
  const CreatorVideoBlock = document.querySelector(".cVvideoBlock");
  if (Hash.includes('#creator')) {
    
    // 크리에이터 정보 가져오기
    const Id = Hash.split('/')[1];
    const CreatorInfo = await fetch('/api/creatorInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id:Id })
    });
    if (!CreatorInfo.ok)return;
    const { creator } = await CreatorInfo.json();
    
    if(creator.avatarUrl){
      CreatorInfoAvatar.src = creator.avatarUrl;
    }else{
      CreatorInfoAvatar.src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
    }
    CreatorInfoName.textContent = creator.name;

    // 크리에이터의 비디오
    CreatorVideoBlock.innerHTML = ``; // 초기화
    creator.videos.forEach(item => {
      const Div = document.createElement('div');
      Div.innerHTML = `
          <a class="cV-block" href="#videodetail/cV/${item._id}" data-id="${item._id}">
              ${item.thumbnailUrl === "thumbnail-None" ? `
                <video class="video" controlsList="nodownload"></video>
              ` : `
                <img src="${item.thumbnailUrl}" class="img" alt="Img">
                <video class="video" controlsList="nodownload" style="display:none"></video>
              `}
              <div class="cV-block-info hidden">
                <div class="creatorAvatarUrl">${item.creator.avatarUrl ? item.creator.avatarUrl : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" }</div>
                <div class="creatorName">${item.creator.name}</div>
                <div class="creatorId">${item.creator._id}</div>
                <div class="title">${item.title}</div>
                <div class="videoUrl">${item.videoUrl}</div>
              </div>
          </a>
      `;
      if(CreatorVideoBlock){
        CreatorVideoBlock.appendChild(Div);

        const AllCvBlock = document.querySelectorAll('.cV-block');
        AllCvBlock.forEach((block,index) => {
          const Video = block.querySelector('video');
          if(Video){
            const blockVideoUrl = block.querySelector('.videoUrl').textContent;
            const VideoUrl = `https://video.ponigress.com/${blockVideoUrl}`;
    
            if (Hls.isSupported()) {
              const hls = new Hls();
              hls.loadSource(VideoUrl);
              hls.attachMedia(Video);
              hls.on(Hls.Events.MANIFEST_PARSED, function () {
                hls.currentLevel = 1;
              });
              const handleCanPlay = () => {
                Video.muted = true;
                Video.loop = true;
                Video.currentTime = 0.5;
                Video.removeEventListener('canplaythrough', handleCanPlay);
                setTimeout(() => {
                  Video.play();
                }, 1000);
              };
              Video.addEventListener('canplaythrough', handleCanPlay);
            } else if (Video.canPlayType('application/vnd.apple.mpegurl')) {
              Video.src = VideoUrl;
            }
          }
        });
      }
    });
  }else{
    CreatorInfoAvatar.src = "";
    CreatorInfoName.textContent = "";
    CreatorVideoBlock.innerHTML = ``;
  }
}
if (UserID) {
  window.addEventListener("load", creatorInfo);
  window.addEventListener("hashchange", creatorInfo);
}