// <!-- 회원가입 JAVASCRIPT -->
const SignUp = document.querySelector(".signUp");


//// [ 회원가입 언어번역 ]
function signUpTrans(){
    const SignUpName = document.querySelector(".signUp_name"); // 이름
    const SignUpEmail = document.querySelector(".signUp_email"); // 이메일
    const SignUpPasswordA = document.querySelector(".signUp_passwordA"); // 패스워드
    const SignUpPasswordB = document.querySelector(".signUp_passwordB"); // 패스워드 재확인
    const SignUpBtn = document.querySelector(".signUp_btn"); // 회원가입 (버튼)
    const TermsTEXT = document.querySelector(".termsTEXT"); // 이용약관
    const PrivacyTEXT = document.querySelector(".privacyTEXT"); // 개인정보 처리방침
    const Terms = document.querySelector(".signUp_rull_terms"); // 이용약관
    const Privacy = document.querySelector(".signUp_rull_privacy"); // 개인정보 처리방침
    const SignUpLanguge = document.querySelector(".signUp_language"); // 회원가입 (언어)
    const SignUpText = {
        en: {  // 영어
            SignUpName: "Name",
            SignUpEmail: "Email",
            SignUpPasswordA: "Password",
            SignUpPasswordB: "Confirm Password",
            SignUpBtn: "Sign Up",
            TermsTEXT: "Terms",
            PrivacyTEXT: "Privacy",
            Terms: "Terms",
            Privacy: "Privacy",
            SignUpLanguge: "en"
        },
        ko: {  // 한국어
            SignUpName: "이름",
            SignUpEmail: "이메일",
            SignUpPasswordA: "비밀번호",
            SignUpPasswordB: "비밀번호 확인",
            SignUpBtn: "회원가입",
            TermsTEXT: "이용약관",
            PrivacyTEXT: "개인정보 처리방침",
            Terms: "이용약관",
            Privacy: "개인정보 처리방침",
            SignUpLanguge: "ko"
        }
    }
    const language = navigator.language.split("-")[0];
    const text = SignUpText[language] || SignUpText.en; // 브라우저 해당언어가 없다면 en(영어)를 반환해라!

    if (SignUpName) SignUpName.placeholder = text.SignUpName;
    if (SignUpEmail) SignUpEmail.placeholder = text.SignUpEmail;
    if (SignUpPasswordA) SignUpPasswordA.placeholder = text.SignUpPasswordA;
    if (SignUpPasswordB) SignUpPasswordB.placeholder = text.SignUpPasswordB;
    if (SignUpBtn) SignUpBtn.textContent = text.SignUpBtn;
    if (TermsTEXT) TermsTEXT.textContent = text.TermsTEXT;
    if (PrivacyTEXT) PrivacyTEXT.textContent = text.PrivacyTEXT;
    if (Terms) Terms.textContent = text.Terms;
    if (Privacy) Privacy.textContent = text.Privacy;
    if (SignUpLanguge) SignUpLanguge.value = text.SignUpLanguge;
}
if (SignUp) {
  signUpTrans();
}






//// [ 회원가입 이메일 알림창 - 언어번역 ]
function signUpEmailCheck() {
    const message = {
      en: "Please enter your email", // 영어
      ko: "이메일을 입력하여주세요", // 한국어
    };
    const languages = navigator.language.split("-")[0];
    const Message = message[languages] || message.en;
    alert(Message);
}
  



//// [ 회원가입 비밀번호 알림창 - 언어번역 ]
function signUpPwCheck() {
    const message = {
      en: "Please enter your password", // 영어
      ko: "비밀번호를 입력하여주세요", // 한국어
    };
    const languages = navigator.language.split("-")[0];
    const Message = message[languages] || message.en;
    alert(Message);
}




//// [ 회원가입 동의란 알림창 - 언어번역 ]
function signUpAgreeCheck() {
    const message = {
      en: "Please check the agreement box", // 영어
      ko: "동의란을 체크 하여주세요", // 한국어
    };
    const languages = navigator.language.split("-")[0];
    const Message = message[languages] || message.en;
    alert(Message);
}





//// [ 회원가입 <>은 보안입력 알림창 - 언어번역 ]
function inputRestricted() {
    const message = {
      en: "The '<>' characters are not allowed for security reasons", // 영어
      ko: " '<>' 기호는 보안상 입력이 불가합니다", // 한국어
    };
    const languages = navigator.language.split("-")[0];
    const Message = message[languages] || message.en;
    alert(Message);
}



//// [ 회원가입 비밀번호 일치에러 알림창 - 언어번역 ]
function passwordMismatch() {
    const message = {
      en: "The passwords entered in the two fields do not match", // 영어
      ko: "두 입력란의 비밀번호가 일치하지 않습니다", // 한국어
    };
    const languages = navigator.language.split("-")[0];
    const Message = message[languages] || message.en;
    alert(Message);
}


//// [ 회원가입 존재계정 알림창 - 언어번역 ]
function accountExists() {
    const message = {
      en: "The account already exists", // 영어
      ko: "이미 존재하는 계정입니다", // 한국어
    };
    const languages = navigator.language.split("-")[0];
    const Message = message[languages] || message.en;
    alert(Message);
}





//// [ 회원가입 보이기/숨기기 , 회원가입 제출 , 화윈가입 동의 ]
let isSignUpBtnListenerAdded = false;
const SignUpBackBtn = document.querySelector(".signUp_BackBtn");
function signUpSubmit(){
    const SignUpName = document.querySelector(".signUp_name"); // 이름
    const SignUpEmail = document.querySelector(".signUp_email"); // 이메일
    const SignUpPasswordA = document.querySelector(".signUp_passwordA"); // 패스워드
    const SignUpPasswordB = document.querySelector(".signUp_passwordB"); // 패스워드 재확인
    const SignUpLanguge = document.querySelector(".signUp_language"); // 회원가입 (언어)
    const SignUpBtn = document.querySelector(".signUp_btn"); // 회원가입 (버튼)
    const checkB = document.querySelector(".consentB_check");
    const checkC = document.querySelector(".consentC_check");
    const checkAll = document.querySelector('.checkAll');
    const check = document.querySelectorAll('.check');
    if(window.location.hash === "#signup"){
        SignUp.style.display="flex"; // 회원가입 페이지를 보여주자!

        let width = window.innerWidth;
        if(width > 1000){
            SignUpBackBtn.style.display="none";
        }else{
            SignUpBackBtn.style.display="flex";
        }


        //// [ 회원가입 버튼로딩 및 제출 fetch ]
        if(SignUpBtn !== null){

            function SignUpLoadings(){
                //event.preventDefault(); // 기본 폼 제출 동작을 막습니다.
                if(SignUpEmail.value === ""){ 
                    signUpEmailCheck();
                }else if(SignUpPasswordA.value === ""){
                    signUpPwCheck();
                }else if(SignUpPasswordB.value === ""){
                    signUpPwCheck();
                }else if(checkB.checked === false){
                    signUpAgreeCheck();
                }else if(checkC.checked === false){
                    signUpAgreeCheck();
                }else{
                    SignUpBtn.innerHTML = '<div class="loader"></div>';
                    const username = SignUpEmail.value;
                    const usernames = username.split("@")[0];
                    SignUpName.value = usernames;

                    // 데이터를 서버에 보내기 위한 fetch 요청
                    const formData = {
                        name: SignUpName.value,
                        email: SignUpEmail.value,
                        password: SignUpPasswordA.value,
                        password2: SignUpPasswordB.value,
                        language: SignUpLanguge.value
                    };

                    fetch(`/signup`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(formData)
                    })
                    .then(response => response.json())
                    .then(data => {
                        SignUpBtn.innerHTML = '회원가입'; // 버튼 텍스트 복원
                        if (data.error) {
                            // 에러 메시지 처리
                            if(data.error === "inputRestricted")inputRestricted();
                            if(data.error === "passwordMismatch")passwordMismatch();
                            if(data.error === "accountExists")accountExists();
                        } else {
                            // 성공 시 처리
                            window.location.href = '/#login'; // 로그인 페이지로 리디렉션
                        }
                    })
                }
            }
            if (isSignUpBtnListenerAdded === false) {
                SignUpBtn.addEventListener("click", SignUpLoadings);
                isSignUpBtnListenerAdded = true;
            }
        }


        //// [ 회원가입 동의 모달창 (이용약관,개인정보처리방침) ]
        const consentCtextIcon = document.querySelector('.consentC_text_Icon');
        const tPModalBackground = document.querySelector('.termsPrivacyModal_background');
        const tPcloseIconPC = document.querySelector('.tPcloseIconPC');
        const tPcloseIconMobile = document.querySelector('.tPcloseIconMobile');
        const termsTEXTM = document.querySelector('.termsTEXT');
        const privacyTEXTM = document.querySelector('.privacyTEXT');
        const termsTextInfo = document.querySelector('.termsText-Info');
        const privacyTextInfo = document.querySelector('.privacyText-Info');
        consentCtextIcon.addEventListener("click", function () {
            tPModalBackground.style.display="flex";
        });
        tPcloseIconPC.addEventListener("click", function () {
            tPModalBackground.style.display="none";
        });
        tPcloseIconMobile.addEventListener("click", function () {
            tPModalBackground.style.display="none";
        });
        termsTEXTM.addEventListener("click", function () {
            termsTEXTM.style="background-color:black; color:white;";
            privacyTEXTM.style="background-color:rgb(241, 241, 241); color: black;";
            termsTextInfo.style.display="block";
            privacyTextInfo.style.display="none";
        });
        privacyTEXTM.addEventListener("click", function () {
            termsTEXTM.style="background-color:rgb(241, 241, 241); color: black;";
            privacyTEXTM.style="background-color:black; color:white;";
            termsTextInfo.style.display="none";
            privacyTextInfo.style.display="block";
        });



        // [ 회원가입 form 동의 체크박스 ]
        checkAll.onclick = function(){
            if(checkAll.checked === false){
                for(let i=0; i<check.length; i+=1){ check[i].checked = false; }
            }else{
                for(let i=0; i<check.length; i+=1){ check[i].checked = true; }
            }
        };
        for(let i=0; i<check.length; i+=1){
            check[i].onclick = function(){
                if( this.checked === false ){ checkAll.checked = false; }
            }
        }


        //// [ 회원가입 form 동의 텍스트 ]
        const consentA = document.querySelector(".consentA_text"); // 전체동의
        const consentB = document.querySelector(".consentB_text"); // 만 14세 이상 입니다.
        const consentC = document.querySelector(".consentC_text"); // 이용약관과 개인정보처리방침에 동의 합니다.
        const SignUpConsent = {
            en: {  // 영어
                consentA: "I agree to all",
                consentB: "I am 14 years old or older",
                consentC: "I agree to the terms of service and privacy policy"
            },
            ko: {  // 한국어
                consentA: "전체동의",
                consentB: "14세 이상입니다",
                consentC: "이용약관과 개인정보 처리방침에 동의합니다"
            }
        };
        function SignUpGuideTrans(){
            const language = navigator.language.split("-")[0];
            const text = SignUpConsent[language] || SignUpConsent.en; // 브라우저 해당언어가 없다면 en(양어)를 반환해라!

            if (consentA) consentA.textContent = text.consentA;
            if (consentB) consentB.textContent = text.consentB;
            if (consentC) consentC.textContent = text.consentC;
        }
        SignUpGuideTrans();
    }else{
        SignUp.style.display="none"; // 회원가입 페이지를 숨기자!
        SignUpBackBtn.style.display="none";
        SignUpName.value="";
        SignUpEmail.value="";
        SignUpPasswordA.value="";
        SignUpPasswordB.value="";
        SignUpLanguge.value="";
        checkAll.checked="";
        checkB.checked="";
        checkC.checked="";
    }
}
if(SignUp){
    window.addEventListener('hashchange', signUpSubmit);
    window.addEventListener('load', signUpSubmit);
}



//// [ 회원가입 뒤로가기 ]
function signUpBack() {
  window.history.back();
}
if(SignUp){
    SignUpBackBtn.addEventListener("click", signUpBack);
}