// <!-- 비디오 플레이어 JAVASCRIPT -->
const UserID = document.querySelector(".userID");

//// [ 비디오 플레이어 ]
let Video, ProgressBar, ProgressFilled, VideoPlayPause, VideoPlayPauseIcon, FullScreen, FullScreenIcon, ScreenLock, ScreenLockIcon ;
let IsDragging = false;
let IconTimeout;


// 비디오 컨트롤 초기화 함수
function newVideoControls(videoContainer) {
  Video = videoContainer.querySelector('video');
  ProgressBar = videoContainer.querySelector('.progress-bar');
  ProgressFilled = videoContainer.querySelector('.progress-filled');
  VideoPlayPause = videoContainer.querySelector('.video-play-pause');
  VideoPlayPauseIcon = VideoPlayPause.querySelector('i');

  // 이벤트 리스너 설정
  addEventListeners();
}


// 이벤트 리스너 추가 함수
function addEventListeners() {
  VideoPlayPause.addEventListener('click', togglePlayPause);
  Video.addEventListener('timeupdate', updateProgressBar);
  ProgressBar.addEventListener('click', seekVideo);
  ProgressBar.addEventListener('touchstart', startDrag);
  ProgressBar.addEventListener('touchmove', dragProgressBar);
  document.addEventListener('touchend', stopDrag);
}

// 이벤트 리스너 제거 함수
function clearEventListeners() {
  VideoPlayPause?.removeEventListener('click', togglePlayPause);
  Video?.removeEventListener('timeupdate', updateProgressBar);
  ProgressBar?.removeEventListener('click', seekVideo);
  ProgressBar?.removeEventListener('touchstart', startDrag);
  ProgressBar?.removeEventListener('touchmove', dragProgressBar);
  document.removeEventListener('touchend', stopDrag);
}

// 비디오 재생 상태 변경
function togglePlayPause() {
  const isPaused = Video.paused;
  isPaused ? Video.play() : Video.pause();

  VideoPlayPauseIcon.classList.toggle('bi-play-fill', !isPaused);
  VideoPlayPauseIcon.classList.toggle('bi-pause-fill', isPaused);
  
  showIconTemporarily();
}

// 아이콘 일정 시간 후 숨기기
function showIconTemporarily() {
  VideoPlayPauseIcon.style.display = 'grid';
  clearTimeout(IconTimeout);
  IconTimeout = setTimeout(() => {
    VideoPlayPauseIcon.style.display = 'none';
  }, 700);
}


// 진행 바 업데이트
function updateProgressBar() {
  const percent = (Video.currentTime / Video.duration) * 100;
  ProgressFilled.style.width = `${percent}%`;
}

// 클릭한 위치로 비디오 이동
function seekVideo(e) {
  const position = e.offsetX / ProgressBar.offsetWidth;
  updateVideoTime(position);
}

// 비디오 시간 업데이트
function updateVideoTime(position) {
  Video.currentTime = position * Video.duration;
  ProgressFilled.style.width = `${position * 100}%`;
}

// 드래그 시작
function startDrag(e) {
  IsDragging = true;
  dragProgressBar(e);
}

// 드래그 중 비디오 진행 바 업데이트
function dragProgressBar(e) {
  if (!IsDragging) return;

  const { left, width } = ProgressBar.getBoundingClientRect();
  const offsetX = e.touches[0].clientX - left;
  const position = Math.max(0, Math.min(offsetX / width, 1));
  
  updateVideoTime(position);
}

// 드래그 종료
function stopDrag() {
  IsDragging = false;
}

// 비디오 플레이 설정
function videoPlay() {
  const hash = window.location.hash;
  if (hash.includes('#videodetail')) {
    const MainVideoContainer = document.querySelector('.videoDetail video');
    const MainVideoGroup = document.querySelector('.mainVideo-group');
    if(MainVideoContainer){
      clearEventListeners(); // 기존 이벤트 제거
      newVideoControls(MainVideoGroup); // 새 비디오 초기화
    }
  }
}

if(UserID) {
  window.addEventListener('hashchange', videoPlay);
  window.addEventListener('load', videoPlay);
}