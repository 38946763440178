// <!-- 비번찾기 JAVASCRIPT -->
const FindPw = document.querySelector(".findPw");


//// [ 비밀번호 찾기 언어번역 ]
function findPwTrans(){
    const FindPwTitle = document.querySelector(".findPw_title"); // 비밀번호 찾기(제목)
    const FindPwEmail = document.querySelector(".findPw_email"); // 이메일을 입력하여주세요
    const FindPwSubmitBtn = document.querySelector(".findPw_submitBtn"); // 확인(버튼)
    const FindPwForgotId = document.querySelector(".findPw_forgot_id p"); // 이메일을 잊으셨나요?
    
    const FindPwText = {
        en: { // 영어
            FindPwTitle: "Find Password",
            FindPwEmail: "Please enter your email",
            FindPwSubmitBtn: "Confirm",
            FindPwForgotId: "Forgot email?"
        },
        ko: {  // 한국어
            FindPwTitle: "비밀번호 찾기",
            FindPwEmail: "이메일을 입력해주세요.",
            FindPwSubmitBtn: "확인",
            FindPwForgotId: "이메일을 잊으셨나요?"
        }
    };
    const Language = navigator.language.split("-")[0];
    const Text = FindPwText[Language] || FindPwText.en;

    if (FindPwTitle) FindPwTitle.textContent = Text.FindPwTitle;
    if (FindPwEmail) FindPwEmail.placeholder = Text.FindPwEmail;
    if (FindPwSubmitBtn) FindPwSubmitBtn.textContent = Text.FindPwSubmitBtn;
    if (FindPwForgotId) FindPwForgotId.textContent = Text.FindPwForgotId;
}
if (FindPw){
  findPwTrans();
}





//// [ 비밀번호 찾기 페이지 보이기/숨기기  ]
function findPwJS(){
    const FindPwBackBtn = document.querySelector(".findPw_BackBtn");
    if(window.location.hash === "#findpw"){
        FindPw.style.display="flex";

        let width = window.innerWidth;
        if(width > 1000){
            FindPwBackBtn.style.display="none";
        }else{
            FindPwBackBtn.style.display="flex";
        }
    } else {
        FindPw.style.display="none";
        FindPwBackBtn.style.display="none";
    }
}
if(FindPw){
    window.addEventListener('hashchange', findPwJS);
    window.addEventListener('load', findPwJS);
}





//// [ 비밀번호 찾기 뒤로가기 ]
const FindPwBackBtnIcon = document.getElementById("findPw_BackBtnIcon");
function findPwBack() {
    window.history.back();
}
if(FindPw){
    FindPwBackBtnIcon.addEventListener("click", findPwBack);
}
  



//// [ 임시 비밀번호 보내기 ]
function sendPw(){
    //// [ 알림 언어번역 ]
    const FindPwSubmitBtn = document.querySelector(".findPw_submitBtn");
    const TemporaryPwEmail = {
        en: "We have sent a temporary password to your email", // 영어
        ko: "임시 비밀번호가 이메일로 전송되었습니다" // 한국어
    };
    function sendPwHandle() {
        const Language = navigator.language.split("-")[0];
        const TemporaryPwEmails = TemporaryPwEmail[Language] || TemporaryPwEmail.en;
        alert(TemporaryPwEmails);
    }
    
    //// [ 알림 언어번역 ]
    const UserNotFound  = {
        en: "User not found", // 영어
        ko: "사용자를 찾을 수 없습니다" // 한국어
    };
    function notFoundUser() {
        const Language = navigator.language.split("-")[0];
        const UserNotFounds = UserNotFound[Language] || UserNotFound.en;
        alert(UserNotFounds);
    }
    
    //// [ 알림 언어번역 / fetch ] 
    const EmailMS = {
        en: "temporary password", // 영어
        ko: "임시 비밀번호" // 한국어
    };

    function HandleSubmit(){
        const Email = document.querySelector(".findPw_email").value;
        if(Email){
            FindPwSubmitBtn.innerHTML = '<div class="loader"></div>'; // 로딩 표시 추가
            try {
                const Language = navigator.language.split("-")[0];
                const EmailMassage = EmailMS[Language] || EmailMS.en;
                fetch("/api/findPwAjax",{
                    method: "POST",
                    headers: {
                        "Content-Type":"application/json",
                    },
                    body:JSON.stringify({ Email , EmailMassage})
                })
                .then((response) => {
                    if(response.status === 200){
                        //console.log(response);
                        sendPwHandle();
                        window.location.href="https://www.ponigress.com/#login"; // 배포시 도메인 변경
                        setTimeout(() => {
                            FindPwSubmitBtn.innerHTML = '확인';
                            document.querySelector(".findPw_email").value = "";
                        }, 1000);
                    }else {
                        notFoundUser();
                        FindPwSubmitBtn.innerHTML = '확인';
                    }
                })
            }catch(e){
                //console.log(e);
            }
        }
    };
    function findPwInit(){
        FindPwSubmitBtn.addEventListener("click", HandleSubmit);
    }
    findPwInit();
}
if(FindPw){
    sendPw();
}