// <!-- 모바일 업로드 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


// [ 웹뷰 보이기/숨기기  ]
function webViewVideoUploadJS(){
  const Hash = window.location.hash;
  const WebViewLoadingBg = document.querySelector(".webViewLoadingBg");
  const WebViewVideoUploadBg = document.querySelector(".webViewVideoUpload-bg");
  const VideoGalleryBtn = document.querySelector(".videoGalleryBtn");
  const VideoGalleryCloseBtn = document.querySelector(".videoGalleryCloseBtn");
  const VideoHeadBg = document.querySelector(".videoHead-bg");
  const AddHeadBg = document.querySelector(".addHead-bg");
  const ThumbnailHeadBg = document.querySelector(".thumbnailHead-bg");
  const WebViewTitle = document.querySelector(".webViewTitle");

  if(Hash.includes('#webViewVideoUpload')){
    WebViewLoadingBg.style.display="grid";
    setTimeout(() => {
      WebViewLoadingBg.style.display="none";
    }, 3000);
    WebViewVideoUploadBg.style.display="flex";
    VideoHeadBg.style.display="block";
    AddHeadBg.style.display="none";
    ThumbnailHeadBg.style.display="none";
    VideoGalleryBtn.click();
  } else {
    WebViewLoadingBg.style.display="none";
    WebViewVideoUploadBg.style.display="none";
    WebViewTitle.value = "";
    VideoGalleryCloseBtn.click();
  }
}
if(UserID && window.ReactNativeWebView){
  window.addEventListener('hashchange', webViewVideoUploadJS);
  window.addEventListener('load', webViewVideoUploadJS);
}





//// [ 웹뷰 비디오 업로드 헤드부분 ]
function webViewVideoUploadHead(){
  const VideoHeadBg = document.querySelector(".videoHead-bg");
  const AddHeadBg = document.querySelector(".addHead-bg");
  const ThumbnailHeadBg = document.querySelector(".thumbnailHead-bg");
  const VideoHeadIcon = document.querySelector(".videoHead i");
  const AddHeadIcon = document.querySelector(".addHead i");
  const AddHeadBtn = document.querySelector(".addHead button")
  const VideoHeadBtn = document.querySelector(".videoHead button");
  const ThumbnailHeadBtn = document.querySelector(".thumbnailHead button");
  const VideoGalleryCloseBtn = document.querySelector(".videoGalleryCloseBtn");
  const VideoGalleryHideBtn = document.querySelector(".videoGalleryHideBtn");
  const VideoGalleryShowBtn = document.querySelector(".videoGalleryShowBtn");
  const ThumbnailAdd = document.querySelector(".thumbnailAdd");
  const ThumbnailGalleryBtn = document.querySelector(".thumbnailGalleryBtn");
  const ThumbnailGalleryCloseBtn = document.querySelector(".thumbnailGalleryCloseBtn");
  const WebViewVideoUploadSubmit = document.querySelector(".webViewVideoUploadSubmit");
  const WebViewLoadingBg = document.querySelector(".webViewLoadingBg");
  
  // 비디오헤드 아이콘 클릭 시
  VideoHeadIcon.addEventListener('click', function() {
    window.history.back();
    VideoGalleryCloseBtn.click();
  });
  
  // 비디오헤드 버튼 클릭 시
  VideoHeadBtn.addEventListener('click', function() {
    VideoHeadBg.style.display="none";
    AddHeadBg.style.display="block";
    ThumbnailHeadBg.style.display="none";
    WebViewLoadingBg.style.display="none";
    VideoGalleryHideBtn.click();
  });

  // 추가 아이콘 클릭 시
  AddHeadIcon.addEventListener('click', function() {
    VideoHeadBg.style.display="block";
    AddHeadBg.style.display="none";
    ThumbnailHeadBg.style.display="none";
    WebViewLoadingBg.style.display="grid";
    setTimeout(() => {
      WebViewLoadingBg.style.display="none";
    }, 3000);
    VideoGalleryShowBtn.click();
  });

  // 썸네일 추가 클릭 시
  ThumbnailAdd.addEventListener('click', function() {
    VideoHeadBg.style.display="none";
    AddHeadBg.style.display="none";
    ThumbnailHeadBg.style.display="block";
    WebViewLoadingBg.style.display="grid";
    setTimeout(() => {
      WebViewLoadingBg.style.display="none";
    }, 3000);
    ThumbnailGalleryBtn.click();
  });
  
  // 썸네일 헤드 버튼 클릭 시
  ThumbnailHeadBtn.addEventListener('click', function() {
    VideoHeadBg.style.display="none";
    AddHeadBg.style.display="block";
    ThumbnailHeadBg.style.display="none";
    WebViewLoadingBg.style.display="none";
    ThumbnailGalleryCloseBtn.click();
  });

  // 업로드 버튼 클릭 시
  AddHeadBtn.addEventListener('click', function() {
    AddHeadBtn.style ="background-color:black;";
    AddHeadBtn.innerHTML = '<div class="loader"></div>';
    WebViewVideoUploadSubmit.click();
    setTimeout(() => {
      AddHeadBtn.style ="background-color: rgb(7, 114, 228); color: white;";
      AddHeadBtn.innerHTML = '업로드';
    }, 8000);
  });
}
if(UserID && window.ReactNativeWebView){
  webViewVideoUploadHead();
}








//// [ 1. 웹뷰 제목,언어 DB 저장  ]
let VideoURL ;
function webViewMessage(){
  window.addEventListener('message', (event) => {
    try {
      const VideoId = event.data;
      const WebViewTitle = document.querySelector(".webViewTitle").value;
      const WebViewLanguage = navigator.language.split("-")[0];
      const VideoGalleryCloseBtn = document.querySelector(".videoGalleryCloseBtn");
      const WebViewUploadLoadingBar = document.querySelector('.webViewUpload-loadingBar');
      const StartButton = document.getElementById('start-button');
      
      fetch(`/webViews/webView-videoDBsave`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: VideoId,
          title: WebViewTitle,
          videoLanguage: WebViewLanguage,
        })
      })
      .then(response => response.json())
      .then(data => {
        if(data.videoUrl){
          VideoGalleryCloseBtn.click();
          StartButton.click();
          window.location.href = "https://www.ponigress.com/#home";
          VideoURL = data.videoUrl;
          WebViewUploadLoadingBar.style.display ="flex";
        }
      })
      .catch(error => {
        alert(`제목,언어 DB fetch 오류: ${error}`);
      });
    }catch (error) {
      alert(`제목,언어 DB 저장오류: ${error}`);
    }
  });
}
if(UserID && window.ReactNativeWebView){
  webViewMessage();
}




//// [ 2. 웹뷰 업로드 로딩 ]
const S3videoCheckBtn = document.getElementById('s3videoCheck-button');
function webViewuploadLoading(){
  const startButton = document.getElementById('start-button');
  const loadingBar = document.getElementById('loading-bar');
  
  let loadingInterval;
  let currentProgress = 0;
  const totalTime = 5 * 60 * 1000; // 5분
  let isLoading = false;
  
  // A 버튼 클릭 시 로딩 시작 (70%까지)
  startButton.addEventListener('click', () => {
    if (isLoading) return; // 로딩 중일 때는 중복 실행 방지
  
    isLoading = true;
    currentProgress = 0;
    loadingBar.style.width = currentProgress + '%';
  
    let startTime = Date.now();
  
    loadingInterval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      currentProgress = (elapsedTime / totalTime) * 70; // 70%까지만 진행
  
      if (currentProgress >= 70) {
        clearInterval(loadingInterval);
        currentProgress = 70;
        isLoading = false; // 로딩 종료
        S3videoCheckBtn.click(); // s3 비디오 체크
      }
  
      loadingBar.style.width = currentProgress + '%';
    }, 100); // 100ms마다 진행 상황 업데이트
  });
}
if(UserID && window.ReactNativeWebView){
  webViewuploadLoading();
}





//// [ 3. s3 비디오 체크 ]
// 코드설명: start-button 이 클릭되면 70%를 로딩완료 - s3videoCheck 로 저장유무 확인 - 저장 안되어있을 경우 3분 간격으로 총 5번 호출 ( 0번째 포함 최종 6번 호출 )
// 총 20분동안 업로드 되었는지 검사하는 작업
let callCount = 0; // 호출 횟수를 추적
function s3videoCheck() {
  fetch(`/webViews/webView-s3videoCheck`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      videoUrl: VideoURL,
    }),
  })
  .then(response => response.json())
  .then(data => {
    if (data.fileSave === "yes") {
      alert(`업로드 완료`);
      callCount = 0;
      window.location.href = "https://www.ponigress.com";
    } else {
      //alert(`존재하지 않음`);
      if (callCount <= 5) { // 최대 호출 5번
        callCount++;
        setTimeout(() => {
          S3videoCheckBtn.click();
        }, 3 * 60 * 1000); // 3분 후 호출
      } else {
        alert("업로드 중 오류가 발생하였습니다. 나중에 다시 시도 해주시기 바랍니다.");
        callCount = 0;
        window.location.href = "https://www.ponigress.com";
      }
    }
  })
  .catch(error => {
    alert(`s3 저장유무 오류: ${error}`);
    callCount = 0;
    window.location.href = "https://www.ponigress.com";
  });
}
if(UserID && window.ReactNativeWebView){
  S3videoCheckBtn.addEventListener('click', s3videoCheck);
}