// <!-- 비디오 디테일 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 비디오 디테일 ]
function videoDetailJS() {
  const Hash = window.location.hash;
  const VideoDetail = document.querySelector('.videoDetail');
  const MainVideo = document.querySelector('.mainVideo');
  const MainVideoGroup = document.querySelector('.mainVideo-group');
  const MainCreatorAvatarUrl = document.querySelector('.main-creatorAvatarUrl');
  const MainCreatorName = document.querySelector('.main-creatorName');
  const MainCreatorAtag = document.querySelector('.main-creatorAtag');
  const MainCreatorId = document.querySelector('.main-creatorId');
  const MainTitle = document.querySelector('.mainTitle');
  const MainVideoId = document.querySelector('.main-videoId');
  const MainSaveBtn = document.querySelector(".main-saveBtn");
  const MainSaveValue = document.querySelector('.main-saveValue');
  const EditVideo = document.querySelector('.editVideo');
  const Blocks = document.querySelectorAll('.block');
  
  if(!Hash || Hash.includes('#home') || Hash.includes('#videodetail')){
    Blocks.forEach(block => {
      block.classList.remove('hiddens');
      block.style.marginBottom = '25px';
    });
  } else if(Hash.includes('#mobilesearch')){
    Blocks.forEach(block => {
      block.classList.remove('hiddens');
      block.style.marginBottom = '3px';
    });
  }

  // 해시 변경 시 비디오 제거
  const MainVideoParent = MainVideo.parentNode;
  const BlockVideo = MainVideoParent.querySelector('video');
  if (BlockVideo)MainVideoParent.removeChild(BlockVideo);
  
  if(Hash.includes('#videodetail')){
    VideoDetail.style = "display:flex;";

    // 홈 or 유저 block 에 따른 비디오 가져오기
    let Block, Id;
    if(Hash.includes('uV/')){
      Id = Hash.split('uV/')[1];
      Block = document.querySelector(`.uV-block[data-id="${Id}"]`);
    } else if(Hash.includes('uS/')){
      Id = Hash.split('uS/')[1];
      Block = document.querySelector(`.uS-block[data-id="${Id}"]`);
    } else if(Hash.includes('cV/')){
      Id = Hash.split('cV/')[1];
      Block = document.querySelector(`.cV-block[data-id="${Id}"]`);
    } else if(Hash.includes('sV/')) {
      Id = Hash.split('sV/')[1];
      Block = document.querySelector(`.sV-block[data-id="${Id}"]`);
    } else {
      Id = Hash.split('/')[1];
      Block = document.querySelector(`.block[data-id="${Id}"]`);
    }

    if(Block){
      const BlockVideo = Block.querySelector('.video');
      const BlockImg = Block.querySelector('.img');
      const CreatorAvatarUrl = Block.querySelector('.creatorAvatarUrl').textContent;
      const CreatorName = Block.querySelector('.creatorName').textContent;
      const CreatorId = Block.querySelector('.creatorId').textContent;
      const Title = Block.querySelector('.title').textContent;
      const blockVideoUrl = Block.querySelector('.videoUrl').textContent;
      const VideoUrl = `https://video.ponigress.com/${blockVideoUrl}`;

      if(BlockVideo){
        BlockVideo.style = "display:block";
        
        // New 비디오 생성/추가! (hls 재생)
        const newVideo = document.createElement('video');
        newVideo.classList.add('video'); // .video 클래스 추가
        newVideo.setAttribute('controlsList', 'nodownload'); // 다운로드 방지 설정
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(VideoUrl);
          hls.attachMedia(newVideo);
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            hls.currentLevel = 1;
          });
          const handleCanPlay = () => {
            newVideo.muted = true;
            newVideo.loop = true;
            newVideo.currentTime = 0.5;
            newVideo.removeEventListener('canplaythrough', handleCanPlay);
            setTimeout(() => {
              newVideo.play();
            }, 1000);
          };
          newVideo.addEventListener('canplaythrough', handleCanPlay);
        } else if (newVideo.canPlayType('application/vnd.apple.mpegurl')) {
          newVideo.src = VideoUrl;
        }

        // 이미지(썸네일)이 있을 경우에는 newVideo 를 숨겨라!
        if(BlockImg)newVideo.style = "display:none";
        Block.prepend(newVideo);


        // 메인 비디오로 이동해!
        BlockVideo.currentTime = 0;
        BlockVideo.muted = false; 
        BlockVideo.play(); // 나중에 따로 클릭해서 재생하도록!
        MainVideo.parentNode.appendChild(BlockVideo);
        
        VideoDetail.classList.add("videoDetailCss");
        MainVideoGroup.style="display:flex";
      }

      // 다양한 정보 지정하기
      if(CreatorAvatarUrl){
        MainCreatorAvatarUrl.src = CreatorAvatarUrl;
      }else{
        MainCreatorAvatarUrl.src = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";
      }
      MainCreatorName.textContent = CreatorName;
      if(UserID.textContent === CreatorId){
        MainCreatorAtag.href = `/#users/${CreatorId}`;
      }else{
        MainCreatorAtag.href = `/#creator/${CreatorId}`;
      }
      MainCreatorId.textContent = CreatorId;
      MainTitle.textContent = Title;
      MainVideoId.textContent = Id;
      if(UserID.textContent === CreatorId){
        EditVideo.href = `/#videoEdit/${Id}`;
        EditVideo.style="display:flex";
      }else{
        EditVideo.style="display:none";
      }

      // 동일한 Id 숨기기
      const SameIdBlock = document.querySelector(`.block[data-id="${Id}"]`);
      SameIdBlock.style.marginBottom = '0px';
      if(SameIdBlock)SameIdBlock.classList.add("hiddens");

      // 저장 여부
      const UsBlock = document.querySelector(`.uS-block[data-id="${Id}"]`);
      if(UsBlock){
        MainSaveBtn.textContent = "저장됨";
        MainSaveBtn.style ="background-color:rgba(15, 98, 242, 0); color:white;";
        MainSaveValue.textContent = "yes";
      }else{
        MainSaveBtn.textContent = "저장";
        MainSaveBtn.style ="background-color:rgb(15, 98, 242); color:white;";
        MainSaveValue.textContent = "no";
      }
    }
  }else{
    VideoDetail.classList.remove("videoDetailCss");
    VideoDetail.style = "display:none;";
    MainCreatorAvatarUrl.src = "";
    MainCreatorName.textContent = "";
    MainCreatorId.textContent = "";
    MainTitle.textContent = "";
    MainVideoId.textContent = "";
  }
}
if(UserID){
  window.addEventListener('load', videoDetailJS);
  window.addEventListener('hashchange', videoDetailJS);
}










//// [ 제목 더보기/숨기기 ]
let MyString;
function mainTitle() {
  const Hash = window.location.hash;
  if (!Hash.includes('#videodetail')) return;

  const MainTitle = document.querySelector('.mainTitle');
  const MainCreatorName = document.querySelector('.main-creatorName').textContent;
  let TitleAdd = "<span class='mainTitleAddText'>더보기</span>";
  let TitleHide = "<span class='mainTitleHideText'>숨기기</span>";
  let CreatorName = `<span class='mainCreatorName'>${MainCreatorName} </span>`;

  if (MainTitle) {
    // mainTitle 기능적용
    function truncateString(str) {
      if (str.length > 80) {
        return str.slice(0, 80) + ` ...${TitleAdd}`;
      } else {
        return str;
      }
    }

    MyString = MainTitle.textContent;
    const regURL = new RegExp("(http|https|ftp|telnet|news|irc)://([-/.a-zA-Z0-9_~#%$?&=:200-377()]+)", "gi");
    const MyStrings = `${MyString}`.replace(regURL, "<a href='$1://$2' target='_blank'>$1://$2</a>");
    MainTitle.innerHTML = CreatorName + truncateString(MyStrings);
    let IsTruncated = true;

    // 클릭 이벤트 핸들러
    function toggleTitle() {
      if (IsTruncated) {
        MainTitle.innerHTML = CreatorName + MyStrings + ` ...${TitleHide}`;
        IsTruncated = false;
        attachClickEvent(); // 요소 다시 생성된 후 클릭 이벤트 재등록
      } else {
        MainTitle.innerHTML = CreatorName + truncateString(MyStrings);
        IsTruncated = true;
        attachClickEvent(); // 요소 다시 생성된 후 클릭 이벤트 재등록
      }
    }

    // 이벤트 핸들러 등록 함수
    function attachClickEvent() {
      const AddText = document.querySelector('.mainTitleAddText');
      const HideText = document.querySelector('.mainTitleHideText');
      if (AddText)AddText.addEventListener("click", toggleTitle);
      if (HideText)HideText.addEventListener("click", toggleTitle);
    }
    attachClickEvent();
  }
}
if (UserID) {
  window.addEventListener('load', mainTitle);
  window.addEventListener('hashchange', mainTitle);
}





//// [ 비디오 설정 클릭 (모달창 열기/닫기 / 뒤로가기 클릭 시 모달창부터 닫기) ]
const MainSettingIcon = document.querySelector('.main-seetingIcon');
const SettingModalBg = document.querySelector('.settingModalBg');
const SettingModal = document.querySelector('.settingModal');
function openModal(){
  SettingModalBg.style.display = "block";
}
function closeModal(event) {
  if (!SettingModal.contains(event.target)) {
    SettingModalBg.style.display = "none";
  }
}
function hashCloseModal() {
  SettingModalBg.style.display = "none";
}
if (UserID) {
  MainSettingIcon.removeEventListener('click', openModal);
  MainSettingIcon.addEventListener('click', openModal);
  SettingModalBg.removeEventListener('click', closeModal);
  SettingModalBg.addEventListener('click', closeModal);
  window.addEventListener('hashchange', hashCloseModal);
}








//// [ 비디오 디테일 뒤로가기 ]
function videoDetailBacks(){
  const VideoDetailBack = document.querySelector('.videoDetailBack');
  const Hash = window.location.hash;
  let width = window.innerWidth;

  if(Hash.includes('#videodetail') && width <= 1000){
    VideoDetailBack.style ="display:flex";
  }else{
    VideoDetailBack.style ="display:none";
  }
}
if(UserID){
  window.addEventListener('load', videoDetailBacks);
  window.addEventListener('hashchange', videoDetailBacks);
  window.addEventListener('resize', videoDetailBacks);
  document.getElementById('videoDetailBack').addEventListener('click', function () {
    window.history.back();
  });
}