// <!-- 계정설정 JAVASCRIPT -->
const UserID = document.querySelector(".userID");


//// [ 계정설정 언어번역 ]
function userAccountTrans() {
    const UserAccountTitle = document.querySelector(".userAccount_title");
    const InquiryuploadText = document.querySelector(".userAccount_inquiryupload span");
    const PasswordText = document.querySelector(".userAccount_password span");
    const WithdrawalText = document.querySelector(".userAccount_withdrawal span");
    const LogoutText = document.querySelector(".userAccount_logout span");

    const UserAccountText = {
        en: { // 영어
            UserAccountTitle: "Account Settings",
            InquiryuploadText: "Contact Us",
            PasswordText: "Change Password",
            WithdrawalText: "Delete Account",
            LogoutText: "Log Out"
        },
        ko: { // 한국어
            UserAccountTitle: "계정설정",
            InquiryuploadText: "문의하기",
            PasswordText: "비밀번호 변경",
            WithdrawalText: "계정탈퇴",
            LogoutText: "로그아웃"
        }
    };

    const Language = navigator.language.split("-")[0];
    const Text = UserAccountText[Language] || UserAccountText.en;

    if (UserAccountTitle) UserAccountTitle.textContent = Text.UserAccountTitle;
    if (InquiryuploadText) InquiryuploadText.textContent = Text.InquiryuploadText;
    if (PasswordText) PasswordText.textContent = Text.PasswordText;
    if (WithdrawalText) WithdrawalText.textContent = Text.WithdrawalText;
    if (LogoutText) LogoutText.textContent = Text.LogoutText;
}
if (UserID) {
    userAccountTrans();
}





//// [ 계정설정 해쉬/로드 ]
function userAccountJS(){
    const Hash = window.location.hash;
    const UserAccountBg = document.querySelector(".userAccountBg");
    if(Hash.includes(`#userAccount/${UserID.innerText}`)){
        UserAccountBg.style.display ="flex";
    }else{
        UserAccountBg.style.display ="none";
    }
}
if(UserID){
    window.addEventListener('hashchange', userAccountJS);
    window.addEventListener('load', userAccountJS);
}